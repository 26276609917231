import type { FC } from "react";
import styled from "styled-components";

import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { type EmergencyOrderProductPackageListDto, EmergencyOrderProductPackageStatusEnum } from "src/types";
import { Grid } from "../layouts";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";

export const EmergencyOrderProcessedCell: FC<{ row: EmergencyOrderProductPackageListDto }> = ({ row }) => {
    const { t } = useTranslation();
    const isWaiting = row.status === EmergencyOrderProductPackageStatusEnum.Waiting;
    const approvedLabel = t(
        isWaiting
            ? "dashboard.emergencyOrders.table.processed.approvedThisMonth"
            : "dashboard.emergencyOrders.table.processed.approvedInGivenDate",
    );
    const processedLabel = t(
        isWaiting
            ? "dashboard.emergencyOrders.table.processed.processedThisMonth"
            : "dashboard.emergencyOrders.table.processed.processedInGivenDate",
    );

    return (
        <Grid gridAutoFlow="column" gap="0.5rem" alignItems="center">
            <Tooltip withinPortal={false} backgroundColor={Color.accent50} color={Color.supportGraphite500} withArrow label={approvedLabel}>
                <Text>{row.approvedThisMonth}</Text>
            </Tooltip>
            <Delimiter />
            <Tooltip
                withinPortal={false}
                backgroundColor={Color.accent50}
                color={Color.supportGraphite500}
                withArrow
                label={processedLabel}
            >
                <Text>{row.processedThisMonth}</Text>
            </Tooltip>
        </Grid>
    );
};

const Delimiter = styled.div`
    width: 1px;
    height: 0.8rem;
    background-color: ${Color.neutral300};
`;
