import type { FC } from "react";

import { Flex, LabelValuePair, TabularText, Text, TextLink } from "src/components";
import { routes } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { CustomerStatusEnum } from "src/types";
import { getTranslatedEnumValue } from "src/utils";
import { getCustomerCredibilityColor, getCustomerDetailTextColor } from "./utils";

type ShipToInfoProps = {
    shipToDetail?: CustomerDto;
};

export const ShipToInfo: FC<ShipToInfoProps> = ({ shipToDetail }) => {
    const { t } = useTranslation();
    const textColor = getCustomerDetailTextColor(shipToDetail?.status === CustomerStatusEnum.Inactive);

    return (
        <Flex flexDirection="column" gap="1rem">
            <Text color={Color.primary600} size="1.8rem">
                {t("shipToDetail.customerInfo.title")}
            </Text>
            <TabularText maxWidth="40rem" rowGap="0.2rem">
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.vat")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {shipToDetail?.vatNumber}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.tin")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {shipToDetail?.tin}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.payer")}
                        </Text>
                    }
                    value={
                        <TextLink
                            to={routes.customerDetail.fillPathParams({
                                customerId: shipToDetail?.payer?.id.toString()!,
                            })}
                            size="1.4rem"
                        >
                            {shipToDetail?.payer?.name}
                        </TextLink>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.payerAcn")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {shipToDetail?.payer?.acn}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.chain")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {shipToDetail?.chain}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("shipToDetail.customerInfo.branch")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {shipToDetail?.branch?.name}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("payerDetail.customerInfo.credibility")}
                        </Text>
                    }
                    value={
                        <Text
                            color={getCustomerCredibilityColor(
                                shipToDetail?.status === CustomerStatusEnum.Inactive,
                                shipToDetail?.credibility,
                            )}
                            size="1.4rem"
                        >
                            {getTranslatedEnumValue("customerCredibility", shipToDetail?.credibility)}
                        </Text>
                    }
                />
                <LabelValuePair
                    label={
                        <Text color={textColor} size="1.4rem">
                            {t("payerDetail.customerInfo.type")}
                        </Text>
                    }
                    value={
                        <Text color={textColor} size="1.4rem">
                            {getTranslatedEnumValue("CustomerTypeEnum", shipToDetail?.type)}
                        </Text>
                    }
                />
            </TabularText>
        </Flex>
    );
};
