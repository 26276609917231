import type { FC } from "react";

import notFoundIcon from "src/assets/icons/not_found_customers.svg";
import { Grid, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";

export const NotFound: FC<{ text: string }> = ({ text }) => (
    <Grid alignItems="center" justifyContent="start" gridTemplateColumns="min-content auto" gap="1.5rem">
        <SvgIcon icon={notFoundIcon} />
        <Text color={Color.supportNavy500} size="1.4rem">
            {text}
        </Text>
    </Grid>
);
