import type { FC, ReactNode } from "react";

import { Grid } from "../layouts";

type TableWithButtonsLayoutProps = Readonly<{
    buttons: ReactNode;
    table: ReactNode;
}>;

export const TableWithButtonsLayout: FC<TableWithButtonsLayoutProps> = ({ buttons, table }) => (
    <Grid gridAutoFlow="row" gap="1rem">
        <Grid gridAutoFlow="column" gap="1rem" justifyContent="end">
            {buttons}
        </Grid>
        {table}
    </Grid>
);

TableWithButtonsLayout.displayName = "TableWithButtonsLayout";
