import type { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import foundIcon from "src/assets/icons/found_customers.svg";
import { Grid, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";

export const Found: FC<{ link: string; text: string }> = ({ text, link }) => {
    const { t } = useTranslation();
    return (
        <Grid alignItems="center" justifyContent="start" gridTemplateColumns="min-content auto" gap="1.5rem">
            <SvgIcon icon={foundIcon} />
            <Grid gridAutoFlow="row" gap="0.2rem">
                <Text color={Color.supportNavy500} size="1.4rem">
                    {text}
                </Text>
                <FoundLink to={link}>{t("customers.approveForm.widget.foundLink")}</FoundLink>
            </Grid>
        </Grid>
    );
};
const FoundLink = styled(Link)`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.supportNavy500};
`;
