import type { FC } from "react";

import shipToIcon from "src/assets/icons/ship-to.svg";
import { Button, ModalButtonLayout, SvgIcon } from "src/components";
import { useTranslation } from "src/translations";
import { CustomerDto, CustomerStatusEnum } from "src/types";
import { zipFormatter } from "src/utils";
import type { StartPointEnum } from "../../approveCustomer/constants";
import { FormCustomerInfo } from "../../components";
import { CopyTiersWidget } from "./CopyTiersWidget";

type CopyTiersFormProps = {
    shipTo: CustomerDto;
    onClose: () => void;
    startPoint: StartPointEnum;
};

export const CopyTiersForForm: FC<CopyTiersFormProps> = ({ shipTo, onClose, startPoint }) => {
    const { t } = useTranslation();
    const isPayerInactive = shipTo.payer?.status === CustomerStatusEnum.Inactive;

    return (
        <>
            <FormCustomerInfo
                name={shipTo.name}
                street={shipTo.street}
                city={`${zipFormatter(shipTo.zip)} ${shipTo.city}`}
                icon={<SvgIcon icon={shipToIcon} />}
            />
            <CopyTiersWidget customer={shipTo} active={!isPayerInactive} startPoint={startPoint} />
            <ModalButtonLayout>
                <Button type="button" onClick={onClose} variant="outline">
                    {t("common.cancel")}
                </Button>
            </ModalButtonLayout>
        </>
    );
};
