export const requestMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const url = {
    PRODUCT_PACKAGE: "/product-packages",
    PRODUCT_BRAND: "/product-brands",
    SUPPLY_LIMITS: "/supply-limits",
    SUPPLY_LIMITS_WITH_SHIP_TO_COUNT: "/supply-limits/with-ship-to-count",
    SUPPLY_LIMIT_SUGGESTIONS: "/supply-limits/suggestions",
    SUPPLY_LIMIT_SUGGESTIONS_WITH_CUSTOMERS_CHAIN: "supply-limits/suggestions/with-customer-chains",
    SUPPLY_LIMIT_SUGGESTIONS_APPROVE: "/supply-limits/suggestions/approve",
    SUPPLY_LIMIT_SUGGESTIONS_REFUSE: "/supply-limits/suggestions/refuse",
    PRODUCT_PRICES: "/product-prices",
    PRODUCT_PRICES_PRODUCT_PRICE_LIST: "/product-prices/product-price-list",
    CURRENT_PRODUCT_PRICES: "/product-prices/current-price-for-product-package",
    CUSTOMERS: "/customers",
    CUSTOMER_COUNT: "/customers/count-per-action-request",
    CUSTOMER_UPDATE_CREDIBILITY: "/customers/update-credibility",
    CUSTOMERS_TO_COPY: "/customer-supply-limit/to-copy",
    PRODUCT_BRANDS_WITH_SUPPLY_LIMITS: "customer-supply-limit/brands-with-supply-limits-for-ship-to",
    UPDATE_SUPPLY_LIMIT_AND_MARGIN: "/customer-supply-limit/update-supply-limit-and-margin-for-ship-to",
    SHIP_TO_FOR_SUPPLY_LIMIT: "/customer-supply-limit/ship-to-for-supply-limit",
    BULK_CHANGE_SUPPLY_LIMIT: "/customer-supply-limit/bulk-change-supply-limit",
    PAYER_DISTRIBUTION_MARGIN: "/customer-product-packages/brands-with-margins-for-payer",
    ATTACHMENT: "/attachment",
    CUSTOMER_ATTACHMENT: "/attachment/customer",
    SPECIAL_PRICE_ATTACHMENT: "/attachment/special-price",
    DATA_MODIFICATION_LOG: "/data-modification-logs/customer",
    SPECIAL_PRICES: "/special-prices",
    SPECIAL_PRICES_PRODUCT_BRANDS: "/special-prices/brands-with-special-prices",
    UPDATE_DISTRIBUTION_MARGIN: "/customer-product-packages/update-margins-for-payer",
    RESET_DISTRIBUTION_MARGIN: "/customer-product-packages/reset-margins-for-payer",
    EMERGENCY_ORDERS: "/emergency-orders",
    EMERGENCY_ORDERS_MINIMAL_POSSIBLE_AMOUNT: "/emergency-orders/minimal-possible-amount",
    EMERGENCY_ORDERS_WITH_CUSTOMER_CHAINS: "/emergency-orders/with-customer-chains",
    EMERGENCY_ORDERS_SHIP_TO: "/emergency-orders/ship-to",
    EMERGENCY_ORDER_PRODUCT_PACKAGE_SHIP_TO: "/emergency-orders/emergency-order-product-package",
    EMERGENCY_ORDER_REFUSE: "/emergency-orders/refuse",
    EMERGENCY_ORDER_APPROVE: "/emergency-orders/approve",
    PUBLIC_EMERGENCY_ORDER: "/public/emergency-orders",
    APPROVE_SHIP_TO: "/customer-supply-limit/approve-ship-to",
    UPDATE_SHIP_TO_WITH_SUPPLY_LIMITS: "/customer-supply-limit/copy-supply-limits",
    WITH_SAME_ADDRESS_OR_ACN: "/customers/with-same-address-or-acn",
    CHANGES_TO_UPDATE: "/customers/changes-to-update",
    PAYER_SHIP_TOS: "/customers/ship-to",
    UPDATE_NOTE: "/customers/update-note",
    UPDATE_TRADING_TERMS: "/customers/update-trading-terms",
    UPDATE_CUSTOMER_CONFIRM: "/customers/update-customers-confirm",
    APPROVE_PAYER: "/customers/approve-payer",
    BRANCHES: "/customers/branches",
    CUSTOMERS_TO_BLOCK: "/customers/to-block",
    CUSTOMERS_TO_UNBLOCK: "/customers/to-unblock",
    BLOCK_CUSTOMERS: "/customers/block",
    UNBLOCK_CUSTOMERS: "/customers/unblock",
    ACTIVATE_CUSTOMERS: "/customers/activate",
    DEACTIVATE_CUSTOMERS: "/customers/deactivate",
    CLIENT_CONFIG: "/public/config",
    SHIP_TO_BY_ACN_FOR_EO: "/public/emergency-orders/ship-to",
    PRODUCT_PACKAGE_BY_SUKL_FOR_EO: "/public/emergency-orders/product-package-by-sukl",
    PRODUCT_PACKAGE_BY_NAME_FOR_EO: "/public/emergency-orders/product-package-by-name",
    IMPORT_INTERFACE_OPERATION: "/interface-operation",
    AGGREGATOR_TOOL: "/aggregator-tool",
    AGGREGATOR_TOOL_CSV_DOWNLOAD: "/aggregator-tool/csv",
    AGGREGATOR_TOOL_XLS_DOWNLOAD: "/aggregator-tool/excel",
    AGGREGATOR_TOOL_COUNT: "/aggregator-tool/count",
    AGGREGATOR_TOOL_INTERFACES_WITH_ERROR: "/aggregator-tool/interfaces-with-error",
    AGGREGATOR_TOOL_GENERATE: "/aggregator-tool/generate",
    AGGREGATOR_TOOL_UPLOAD_SAP_FILE: "/aggregator-tool/upload-from-sap",
    LAST_IMPORT_INTERFACE_OPERATION: "/interface-operation/last",
    INTERFACE_ACKNOWLEDGEMENT_ERROR: "/acknowledgement",
    SUKL_DIS13: "/sukl/dis13",
    SUKL_REG13: "/sukl/reg13",
    SPOTLIGHT_SEARCH: "/search",
};
