import type { FC } from "react";
import { useEffect } from "react";

import { useGetCustomerDetailQuery } from "src/api";
import { Modal } from "src/components";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import { CustomerCategoryEnum } from "src/types";
import { TERMS_OF_TRADE_MODAL_WIDTH } from "../constants";
import { ApprovePayerForm } from "./ApprovePayerForm";
import { ApproveShipToForm } from "./ApproveShipToForm";
import { APPROVE_CUSTOMER_MODAL_ID, StartPointEnum } from "./constants";

type ApproveModalProps = {
    customerId: number | undefined;
    onClose: () => void;
    startPoint: StartPointEnum;
};

export const ApproveModal: FC<ApproveModalProps> = ({ customerId, onClose, startPoint }) => {
    const { data: customerDetail } = useGetCustomerDetailQuery(customerId?.toString() || "", { skip: !customerId });
    const [isModalOpened, openModal, closeModal] = useModal(APPROVE_CUSTOMER_MODAL_ID);
    const { t } = useTranslation();

    useEffect(() => {
        if (customerId !== undefined && customerDetail) {
            openModal();
        }
        return () => {
            // we have to close modal, because if you use redirect in widget and return, modal is still opened
            closeModal();
        };
    }, [closeModal, customerDetail, customerId, openModal]);

    const onCloseModal = () => {
        closeModal();
        onClose();
    };

    if (!customerDetail) {
        return null;
    }

    return (
        <Modal title={t("customers.approveForm.title")} opened={isModalOpened} onClose={onCloseModal} maxWidth={TERMS_OF_TRADE_MODAL_WIDTH}>
            {customerDetail.category === CustomerCategoryEnum.Payer ? (
                <ApprovePayerForm payer={customerDetail} onClose={onCloseModal} />
            ) : (
                <ApproveShipToForm shipTo={customerDetail} onClose={onCloseModal} startPoint={startPoint} />
            )}
        </Modal>
    );
};
