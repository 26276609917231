import type { FC } from "react";
import styled from "styled-components";

import searchingIcon from "src/assets/icons/searching_customers.svg";
import { Grid, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";

export const Searching: FC<{ text: string }> = ({ text }) => (
    <Grid alignItems="center" justifyContent="center" gridTemplateColumns="min-content auto" gap="1rem">
        <SearchIconAnimated icon={searchingIcon} />
        <Text color={Color.supportNavy500} size="1.4rem">
            {text}
        </Text>
    </Grid>
);

const SearchIconAnimated = styled(SvgIcon)`
    animation: spin 1s ease infinite;
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`;
