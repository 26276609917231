import { useLocalStorage } from "@mantine/hooks";
import { format } from "date-fns";
import { type FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { api, tags, useGetSupplyLimitSuggestionsQuery } from "src/api";
import { Icon, TabPanel, Tabs } from "src/components";
import { PageLayout } from "src/layout";
import { createSearchParamsAsString, DashboardTab, routes, TAB_PARAM } from "src/routes";
import { useTranslation } from "src/translations";
import { createIsEnum, DATE_TIME_FORMAT, useAppDispatch } from "src/utils";
import { CardLayout, CurrentDate } from "./components";
import { LOCAL_STORAGE_GROUP_BY_CHAIN_KEY } from "./constants";
import {
    CustomersWidget,
    EmergencyOrdersWidget,
    GroupByChainSwitch,
    IncomingInterfacesWidget,
    OutgoingInterfacesWidget,
    SupplyLimitSuggestionsWidget,
} from "./containers";

const isDashboardTabEnum = createIsEnum(DashboardTab);

export const Dashboard: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { data: supplyLimitSuggestions } = useGetSupplyLimitSuggestionsQuery({ pageSize: 1, pageIndex: 0, filters: {} });

    const activeTabFromSearchParam = searchParams.get(TAB_PARAM);

    const activeTab =
        activeTabFromSearchParam && isDashboardTabEnum(activeTabFromSearchParam) ? activeTabFromSearchParam : DashboardTab.emergencyOrders;

    const [groupByChainSwitchState, setGroupByChainSwitchState] = useLocalStorage<Record<DashboardTab, boolean>>({
        key: LOCAL_STORAGE_GROUP_BY_CHAIN_KEY,
        defaultValue: {
            [DashboardTab.emergencyOrders]: false,
            [DashboardTab.supplyLimitSuggestions]: false,
        },
    });

    const onRefresh = () => {
        dispatch(
            api.util.invalidateTags([
                tags.customersCount,
                tags.lastInterfaceOperation,
                tags.emergencyOrdersShipTo,
                tags.supplyLimitSuggestions,
            ]),
        );
        setCurrentDate(new Date());
    };

    return (
        <PageLayout
            toolbar={<CurrentDate onRefresh={onRefresh} date={format(currentDate, DATE_TIME_FORMAT)} />}
            breadcrumbs={[{ name: t("dashboard.name"), route: routes.supplyLimits.path, icon: Icon.Home }]}
        >
            <CardLayout>
                <CustomersWidget />
                <IncomingInterfacesWidget />
                <OutgoingInterfacesWidget />
            </CardLayout>
            <Tabs<DashboardTab>
                activeTab={activeTab}
                onTabChange={(tabValue: DashboardTab) =>
                    navigate(
                        `${routes.dashboard.path}?${createSearchParamsAsString({
                            [TAB_PARAM]: tabValue,
                        })}`,
                    )
                }
                tabs={[
                    {
                        tabValue: DashboardTab.emergencyOrders,
                        label: t("dashboard.emergencyOrders.title"),
                    },
                    {
                        tabValue: DashboardTab.supplyLimitSuggestions,
                        label: t("dashboard.supplyLimitSuggestions.title", { count: supplyLimitSuggestions?.totalElements ?? 0 }),
                    },
                ]}
            >
                <TabPanel value={DashboardTab.emergencyOrders}>
                    <>
                        <GroupByChainSwitch
                            checked={groupByChainSwitchState[DashboardTab.emergencyOrders]}
                            onChange={(event) => {
                                setGroupByChainSwitchState({
                                    ...groupByChainSwitchState,
                                    [DashboardTab.emergencyOrders]: event.currentTarget.checked,
                                });
                            }}
                        />
                        <EmergencyOrdersWidget shouldGroup={groupByChainSwitchState[DashboardTab.emergencyOrders]} />
                    </>
                </TabPanel>
                <TabPanel value={DashboardTab.supplyLimitSuggestions}>
                    <>
                        <GroupByChainSwitch
                            checked={groupByChainSwitchState[DashboardTab.supplyLimitSuggestions]}
                            onChange={(event) =>
                                setGroupByChainSwitchState({
                                    ...groupByChainSwitchState,
                                    [DashboardTab.supplyLimitSuggestions]: event.currentTarget.checked,
                                })
                            }
                        />
                        <SupplyLimitSuggestionsWidget shouldGroup={groupByChainSwitchState[DashboardTab.supplyLimitSuggestions]} />
                    </>
                </TabPanel>
            </Tabs>
        </PageLayout>
    );
};
