import type { TFunction } from "react-i18next";
import type { Row } from "react-table";

import { Button, Confirm, Grid, Icon, TableActionForSelection, Text } from "src/components";
import { routes } from "src/routes";
import { type ExpandRowType, getChildIdFromRowId } from "src/table";
import { Color, IconSizes } from "src/theme";
import { type EmergencyOrderProductPackageListDto, type ExpandEmergencyOrdersType, CustomerCredibilityEnum } from "src/types";
import { LinkToPublicEmergencyForm } from "../../components";
import { ApproveWithXOrdersForm } from "./ApproveWithXOrdersForm";
import { RefuseForm } from "./RefuseForm";

export const createActionsForSelection =
    (t: TFunction, bulkApprove: (value: { emergencyOrderProductPackageIds: number[] }) => void) =>
    (_: boolean, selectedRowIds: Record<number, boolean>) => {
        const selectedIds = Object.entries(selectedRowIds).map(([key]) => {
            const childRowId = getChildIdFromRowId(key);
            return childRowId ? Number(childRowId) : Number(key);
        });
        return (
            <TableActionForSelection>
                <LinkToPublicEmergencyForm target="_blank" href={routes.emergencyOrders.path}>
                    <Grid gridAutoFlow="column" gap="0.5rem">
                        {t("dashboard.emergencyOrders.create")}
                        <Icon.Open size={IconSizes.s} filled />
                    </Grid>
                </LinkToPublicEmergencyForm>
                <ApproveWithXOrdersForm selectedIds={selectedIds} />
                <Confirm
                    title={t("dashboard.emergencyOrders.approveConfirm.title")}
                    content={t("dashboard.emergencyOrders.approveConfirm.content")}
                    confirmButtonTransKey="dashboard.emergencyOrders.approveSelected"
                    onConfirm={() => bulkApprove({ emergencyOrderProductPackageIds: selectedIds })}
                >
                    {(open) => (
                        <Button disabled={selectedIds.length === 0} type="button" onClick={open}>
                            {t("dashboard.emergencyOrders.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <RefuseForm selectedIds={selectedIds} />
            </TableActionForSelection>
        );
    };

export const createExpandRow = (row: Row<ExpandEmergencyOrdersType>): ExpandRowType => ({
    accessor: "requestedOn",
    colspan: 13,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.chain}
        </Text>
    ),
});

export const overrideRowProps = (row: Row<EmergencyOrderProductPackageListDto>): Record<string, unknown> =>
    getCredibilityColor(row.values.customerCredibility);

const EMERGENCY_ORDER_PRODUCT_PACKAGE_DEPTH_LEVEL = 1;
export const overrideGroupedTableRowProps = (row: Row<ExpandEmergencyOrdersType>): Record<string, unknown> =>
    row.depth === EMERGENCY_ORDER_PRODUCT_PACKAGE_DEPTH_LEVEL
        ? getCredibilityColor(row.values.customerCredibility)
        : { $backgroundColor: Color.white };

const getCredibilityColor = (credibility: CustomerCredibilityEnum | undefined): Record<string, unknown> => {
    switch (credibility) {
        case CustomerCredibilityEnum.Good:
            return { $backgroundColor: Color.success50 };
        case CustomerCredibilityEnum.Suspicious:
            return { $backgroundColor: Color.warning50 };
        case CustomerCredibilityEnum.Bad:
            return { $backgroundColor: Color.error50 };
        default:
            return { $backgroundColor: Color.white };
    }
};
