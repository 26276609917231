import type { FC } from "react";

import { FileMimeType, url, useDownloadFileMutation, useGetAggregationsQuery } from "src/api";
import { Button, TableWithButtonsLayout } from "src/components";
import { useModal } from "src/modal";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import { EXPORT_SAP_FILE_MODAL, IMPORT_SAP_FILE_MODAL } from "./constants";
import { AggregationFileType } from "./enums";
import { ExportSapFileForm } from "./ExportSapFileForm";
import { ImportSapFileForm } from "./ImportSapFileForm";
import { tableModule } from "./table";
import { createActionColumn, overrideAggregatorToolTableRowProps } from "./utils";

export const AggregationTool: FC = () => {
    const { t } = useTranslation();
    const notifications = useNotifications();
    const [isExportSapFileModalOpened, openExportSapFileModal, closeExportSapFileModal] = useModal(EXPORT_SAP_FILE_MODAL);
    const [isImportSapFileModalOpened, openImportSapFileModal, closeImportSapFileModal] = useModal(IMPORT_SAP_FILE_MODAL);
    const [downloadFile] = useDownloadFileMutation();
    const { data: lastAggregation } = useGetAggregationsQuery({
        pageSize: 1,
        pageIndex: 0,
        filters: {},
    });

    const downloadAggregationFile = async (fileType: AggregationFileType, aggregationId: number, filename: string) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t(`importExport.aggregatorTool.table.download.${fileType}.loading`),
            loading: true,
        });
        try {
            if (fileType === AggregationFileType.Csv) {
                await downloadFile({
                    url: `${url.AGGREGATOR_TOOL_CSV_DOWNLOAD}/${aggregationId}`,
                    filename,
                    fileMimeType: FileMimeType.Csv,
                }).unwrap();
            } else if (fileType === AggregationFileType.Xls) {
                await downloadFile({
                    url: `${url.AGGREGATOR_TOOL_XLS_DOWNLOAD}/${aggregationId}`,
                    filename,
                    fileMimeType: FileMimeType.Xls,
                }).unwrap();
            } else {
                throw new Error(`Invalid aggregation file type: ${fileType}`);
            }
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t("importExport.aggregatorTool.table.download.success"),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`importExport.aggregatorTool.table.download.${fileType}.error`),
            });
        }
    };

    return (
        <>
            <ExportSapFileForm
                isOpen={isExportSapFileModalOpened}
                lastAggregation={lastAggregation?.content?.[0]}
                closeModal={closeExportSapFileModal}
            />
            <ImportSapFileForm closeModal={closeImportSapFileModal} isOpen={isImportSapFileModalOpened} />
            <TableWithButtonsLayout
                buttons={
                    <>
                        <Button type="button" onClick={openExportSapFileModal}>
                            {t("importExport.aggregatorTool.exportSapFile.open")}
                        </Button>
                        <Button variant="outline" type="button" onClick={openImportSapFileModal}>
                            {t("importExport.aggregatorTool.importSapFile.open")}
                        </Button>
                    </>
                }
                table={
                    <tableModule.Container
                        overrideRowProps={overrideAggregatorToolTableRowProps}
                        actions={createActionColumn(t, downloadAggregationFile)}
                    />
                }
            />
        </>
    );
};
