import { useGetSupplyLimitSuggestionsQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { SupplyLimitSuggestionDto } from "src/types";
import { createColumns } from "./createColumns";

export const tableModule = createTableModule<SupplyLimitSuggestionDto>(
    TableNames.supplyLimitSuggestions,
    useGetSupplyLimitSuggestionsQuery,
    createColumns,
);
