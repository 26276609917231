import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";

import { useLazyGetCustomersWithSameAddressOrAcnQuery } from "src/api";
import { CopyTiersWidgetComponent } from "src/app/customers/components";
import { createSearchParamsAsString, routes } from "src/routes";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { cleanObject, EMPTY_ARRAY, FALSE_STRING, isEmpty, TRUE_STRING } from "src/utils";
import type { StartPointEnum } from "../../approveCustomer/constants";
import { ACN_PARAM, ACTIVE_PARAM, IS_EXISTING_SHIP_TO, START_POINT_PARAM } from "../../constants";

type CopyTiersWidgetProps = {
    customer: CustomerDto;
    active: boolean;
    startPoint: StartPointEnum;
};

export const CopyTiersWidget: FC<CopyTiersWidgetProps> = ({ customer, active, startPoint }) => {
    const [acnForSearch, setAcnForSearch] = useState<string>("");
    const { t } = useTranslation();
    const [trigger, { data, isLoading, isFetching, isSuccess }] = useLazyGetCustomersWithSameAddressOrAcnQuery();

    useEffect(() => {
        trigger({ targetShipToId: customer.id });
    }, [customer.id, trigger]);

    const onSearchByAcn = (acn: string) => {
        setAcnForSearch(acn);
        trigger({ targetShipToId: customer.id, acn });
    };

    const notFoundText = isEmpty(acnForSearch)
        ? t("customers.copyTiersForm.widget.notFound")
        : t("customers.copyTiersForm.widget.notFoundWithAcn", { acn: acnForSearch });

    const foundText = t("customers.copyTiersForm.widget.foundWithAcn", { acn: acnForSearch });

    const foundLink = useMemo(
        () =>
            `${routes.copyTiers.fillPathParams({ targetShipToId: customer.id.toString() })}?${createSearchParamsAsString(
                cleanObject({
                    [ACN_PARAM]: acnForSearch === "" ? undefined : acnForSearch,
                    [ACTIVE_PARAM]: active ? TRUE_STRING : FALSE_STRING,
                    [START_POINT_PARAM]: startPoint,
                    [IS_EXISTING_SHIP_TO]: TRUE_STRING,
                }),
            )}`,
        [customer.id, acnForSearch, active, startPoint],
    );

    return (
        <CopyTiersWidgetComponent
            isSearching={isLoading || isFetching}
            onSearchByAcn={onSearchByAcn}
            sameCustomers={isSuccess ? data : EMPTY_ARRAY}
            notFoundText={notFoundText}
            foundText={foundText}
            foundLink={foundLink}
        />
    );
};
