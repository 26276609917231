// Table names must be unique.
export enum TableNames {
    customerAttachments = "CA",
    payerDataModificationLog = "PDML",
    shipToDataModificationLog = "STDML",
    supplyLimits = "SL",
    payerDistributionMargin = "PDM",
    payerSpecialPrices = "PSP",
    payerShipTo = "PST",
    shipToEmergencyOrders = "STEO",
    shipToSpecialPrices = "STSP",
    ShipToSupplyLimits = "STSL",
    customersAll = "CA",
    customersBlocked = "CB",
    customersDeactivated = "CD",
    customersNew = "CN",
    customersUnblocked = "CUn",
    customersUpdated = "CUp",
    emergencyOrders = "EO",
    groupedEmergencyOrders = "GEO",
    importInterfaces = "II",
    exportInterfaces = "EI",
    supplyLimitBulkEdit = "SLBE",
    supplyLimitSuggestions = "SLS",
    groupedSupplyLimitSuggestions = "GSLS",
    aggregatorTool = "AT",
    productList = "PL",
    productPrice = "PP",
}
