import type { FC } from "react";

import { createGlobalErrorMessage, useApproveEmergencyOrdersMutation } from "src/api";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { useAppDispatch } from "src/utils";
import { DashboardCard } from "../DashboardCard";
import { groupedTableModule } from "./groupedTable";
import { tableModule } from "./table";
import { createActionsForSelection, createExpandRow, overrideGroupedTableRowProps, overrideRowProps } from "./utils";

type EmergencyOrdersWidgetProps = Readonly<{
    shouldGroup: boolean;
}>;
export const EmergencyOrdersWidget: FC<EmergencyOrdersWidgetProps> = ({ shouldGroup }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const notifications = useNotifications();

    const [approve] = useApproveEmergencyOrdersMutation();

    const onBulkApprove = (value: { emergencyOrderProductPackageIds: number[] }) => {
        approve(value)
            .unwrap()
            .then(() => {
                dispatch(tableModule.resetTableSelectionAction);
                dispatch(groupedTableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("dashboard.emergencyOrders.successApproveBulkMessage"),
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(error, t("dashboard.emergencyOrders.errorApproveBulkMessage"));
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    type: NotificationTypes.error,
                });
            });
    };

    return (
        <DashboardCard>
            {shouldGroup ? (
                <groupedTableModule.Container
                    actionsForSelection={createActionsForSelection(t, onBulkApprove)}
                    isSelectable
                    canSelectAll={false}
                    expandedRow={createExpandRow}
                    overrideRowProps={overrideGroupedTableRowProps}
                />
            ) : (
                <tableModule.Container
                    actionsForSelection={createActionsForSelection(t, onBulkApprove)}
                    isSelectable
                    canSelectAll={false}
                    overrideRowProps={overrideRowProps}
                />
            )}
        </DashboardCard>
    );
};
