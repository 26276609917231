import styled from "styled-components";

export const Widget = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 1.5rem;
    padding: 1.6rem;
    background-color: ${({ theme }) => theme.color.neutral50};
    border: 0.5px solid ${({ theme }) => theme.color.neutral200};
    border-radius: ${({ theme }) => theme.radius.default};
`;
