import type { FC } from "react";
import { useState } from "react";

import { useDeleteProductPriceMutation } from "src/api";
import { Button, Icon, TableWithButtonsLayout } from "src/components";
import { PageLayout } from "src/layout";
import { useModalControls } from "src/modal";
import { useMutationNotification } from "src/notifications";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import type { ProductPackageWithPricesDto, ProductPriceDto } from "src/types";
import { PRODUCT_PRICE_LIST_MODAL_ID, PRODUCT_PRICE_MODAL_ID } from "./constants";
import { PriceListForm, ProductPriceForm } from "./forms";
import { tableModule } from "./table";
import { createActionColumn, createExpandRow, overrideRowProps } from "./utils";

export const Prices: FC = () => {
    const [openProductBrandModal] = useModalControls(PRODUCT_PRICE_MODAL_ID);
    const [openPriceListModal] = useModalControls(PRODUCT_PRICE_LIST_MODAL_ID);
    const [productPackageWithPrices, setProductPackageWithPrices] = useState<ProductPackageWithPricesDto | undefined>(undefined);
    const [productPriceForEdit, setProductPriceForEdit] = useState<ProductPriceDto | undefined>(undefined);
    const [deleteProductPrice, deleteResult] = useDeleteProductPriceMutation();
    useMutationNotification(deleteResult, "prices.deleteSuccess", "prices.deleteError");
    const { t } = useTranslation();

    const openProductPriceFormModal = (productPackage: ProductPackageWithPricesDto, productPrice?: ProductPriceDto) => {
        setProductPackageWithPrices(productPackage);
        setProductPriceForEdit(productPrice);
        openProductBrandModal();
    };

    return (
        <>
            <ProductPriceForm productPackageWithPrices={productPackageWithPrices} productPriceForEdit={productPriceForEdit} />
            <PriceListForm />
            <PageLayout
                breadcrumbs={[
                    { name: t("products.name"), route: routes.products.path, icon: Icon.Box },
                    { name: t("prices.name"), route: routes.prices.path },
                ]}
            >
                <TableWithButtonsLayout
                    buttons={
                        <Button type="button" onClick={openPriceListModal}>
                            {t("prices.generatePriceList")}
                        </Button>
                    }
                    table={
                        <tableModule.Container
                            overrideRowProps={overrideRowProps}
                            expandedRow={createExpandRow}
                            actions={createActionColumn(t, openProductPriceFormModal, deleteProductPrice)}
                        />
                    }
                />
            </PageLayout>
        </>
    );
};
