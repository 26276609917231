import type { TableQueryParams } from "src/table";
import type {
    BulkChangeSupplyLimitDto,
    BulkChangeSupplyLimitFiltersDto,
    BulkChangeSupplyLimitRequestDto,
    CreateSupplyLimitDto,
    CustomerBranchDto,
    ExpandTableSupplySuggestionType,
    GenericPaginationRequestDto,
    PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto,
    PaginationResponseDtoSupplyLimitSuggestionDto,
    ShipToForSupplyLimitDto,
    SupplyLimitDto,
    SupplyLimitsForShipToAndProductPackageDto,
    SupplyLimitWithShipToCountDto,
    UpdateSupplyLimitAndMarginForShipToDto,
    UpdateSupplyLimitDto,
} from "src/types";
import { api } from "../../baseQuery";
import { requestMethod, url } from "../../constants";
import { tags } from "../../tags";
import { convertShipToForSupplyLimitTableFilterNames } from "./utils";

export { convertShipToForSupplyLimitTableFilterNames } from "./utils";

const supplyLimitsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSupplyLimitsWithShipToCount: builder.query<Array<SupplyLimitWithShipToCountDto>, string>({
            query: (packageId) => ({
                url: `${url.SUPPLY_LIMITS_WITH_SHIP_TO_COUNT}`,
                params: { packageId },
            }),
            // Do not use cache for this query, because otherwise loading will not appear
            // when you enter the page for second time in 30 seconds.
            // We need to show loading because rendering array of supply limits is slow
            keepUnusedDataFor: 1,
            providesTags: [tags.supplyLimits],
        }),
        getSupplyLimits: builder.query<Array<SupplyLimitDto>, string>({
            query: (packageId) => ({
                url: `${url.SUPPLY_LIMITS}`,
                params: { packageId },
            }),
            providesTags: [tags.supplyLimits],
        }),
        getCustomerBranches: builder.query<Array<CustomerBranchDto>, void>({
            query: () => ({
                url: `${url.BRANCHES}`,
            }),
            providesTags: [tags.supplyLimits],
        }),
        createSupplyLimit: builder.mutation<void, CreateSupplyLimitDto>({
            query: (values) => ({
                url: url.SUPPLY_LIMITS,
                method: requestMethod.POST,
                body: values,
            }),
            invalidatesTags: [tags.supplyLimits, tags.emergencyOrdersShipToModal, tags.emergencyOrdersShipTo],
        }),
        updateSupplyLimit: builder.mutation<void, UpdateSupplyLimitDto>({
            query: (values) => ({
                url: url.SUPPLY_LIMITS,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.supplyLimits, tags.emergencyOrdersShipToModal, tags.emergencyOrdersShipTo],
        }),
        getShipToForSupplyLimit: builder.query<GenericPaginationRequestDto<ShipToForSupplyLimitDto>, TableQueryParams>({
            query: (queryParams) => ({
                url: url.SHIP_TO_FOR_SUPPLY_LIMIT,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...convertShipToForSupplyLimitTableFilterNames(queryParams.filters),
                },
            }),
            providesTags: [tags.supplyLimits],
        }),
        getSupplyLimitOptions: builder.query<BulkChangeSupplyLimitDto, BulkChangeSupplyLimitFiltersDto>({
            query: (queryParams) => ({
                url: url.BULK_CHANGE_SUPPLY_LIMIT,
                params: queryParams,
            }),
            providesTags: [tags.supplyLimits, tags.supplyLimitsOption],
        }),
        updateBulkSupplyLimit: builder.mutation<void, BulkChangeSupplyLimitRequestDto>({
            query: (values) => ({
                url: url.BULK_CHANGE_SUPPLY_LIMIT,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.supplyLimits, tags.emergencyOrdersShipToModal, tags.emergencyOrdersShipTo],
        }),
        getSupplyLimitForShipToAndProductPackage: builder.query<
            SupplyLimitsForShipToAndProductPackageDto,
            { shipToId: number | string; productPackageId: number | string }
        >({
            query: ({ shipToId, productPackageId }) => ({
                url: `${url.SUPPLY_LIMITS}/ship-to/${shipToId}/product-package/${productPackageId}`,
            }),
            providesTags: [tags.supplyLimits, tags.supplyLimitsOption],
        }),
        updateSupplyLimitAndMargin: builder.mutation<void, UpdateSupplyLimitAndMarginForShipToDto>({
            query: (values) => ({
                url: url.UPDATE_SUPPLY_LIMIT_AND_MARGIN,
                method: requestMethod.PUT,
                body: values,
            }),
            invalidatesTags: [tags.supplyLimits, tags.supplyLimitsOption, tags.emergencyOrdersShipToModal, tags.emergencyOrdersShipTo],
        }),
        getSupplyLimitSuggestions: builder.query<PaginationResponseDtoSupplyLimitSuggestionDto, TableQueryParams>({
            query: (queryParams) => ({
                url: url.SUPPLY_LIMIT_SUGGESTIONS,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...queryParams.filters,
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.supplyLimits, tags.supplyLimitSuggestions],
        }),
        getGroupedSupplyLimitSuggestions: builder.query<GenericPaginationRequestDto<ExpandTableSupplySuggestionType>, TableQueryParams>({
            query: (queryParams) => ({
                url: url.SUPPLY_LIMIT_SUGGESTIONS_WITH_CUSTOMERS_CHAIN,
                params: {
                    pageSize: queryParams.pageSize,
                    pageNumber: queryParams.pageIndex + 1,
                    ...queryParams.filters,
                },
            }),
            keepUnusedDataFor: 0,
            providesTags: [tags.supplyLimits, tags.supplyLimitSuggestions],
            transformResponse: (
                response: PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto,
            ): GenericPaginationRequestDto<ExpandTableSupplySuggestionType> => {
                return {
                    ...response,
                    content: response.content.map((chain) => ({
                        ...chain,
                        id: chain.chain,
                        subRows: chain.supplyLimitSuggestions.map((suggestion) => ({ ...suggestion, chain: chain.chain })),
                    })),
                };
            },
        }),
        approveSupplyLimitSuggestion: builder.mutation<void, ReadonlyArray<number>>({
            query: (suggestionIds) => ({
                url: url.SUPPLY_LIMIT_SUGGESTIONS_APPROVE,
                method: requestMethod.PUT,
                params: {
                    suggestionIds,
                },
            }),
            invalidatesTags: [tags.supplyLimits, tags.supplyLimitsOption, tags.supplyLimitSuggestions],
        }),
        refuseSupplyLimitSuggestion: builder.mutation<void, ReadonlyArray<number>>({
            query: (suggestionIds) => ({
                url: url.SUPPLY_LIMIT_SUGGESTIONS_REFUSE,
                method: requestMethod.PUT,
                params: {
                    suggestionIds,
                },
            }),
            invalidatesTags: [tags.supplyLimits, tags.supplyLimitsOption, tags.supplyLimitSuggestions],
        }),
    }),
});

export const {
    useUpdateBulkSupplyLimitMutation,
    useUpdateSupplyLimitMutation,
    useGetSupplyLimitOptionsQuery,
    useGetSupplyLimitsQuery,
    useGetSupplyLimitsWithShipToCountQuery,
    useGetShipToForSupplyLimitQuery,
    useCreateSupplyLimitMutation,
    useGetCustomerBranchesQuery,
    useGetSupplyLimitForShipToAndProductPackageQuery,
    useUpdateSupplyLimitAndMarginMutation,
    useGetSupplyLimitSuggestionsQuery,
    useGetGroupedSupplyLimitSuggestionsQuery,
    useApproveSupplyLimitSuggestionMutation,
    useRefuseSupplyLimitSuggestionMutation,
} = supplyLimitsApi;
