import type { ChangeEvent, FC } from "react";
import styled from "styled-components";

import { Flex, Switch } from "src/components";
import { useTranslation } from "src/translations";

type GroupByChainSwitchProps = Readonly<{
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}>;

export const GroupByChainSwitch: FC<GroupByChainSwitchProps> = ({ onChange, checked }) => {
    const { t } = useTranslation();

    return (
        <Layout justifyContent="end">
            <Switch label={t("dashboard.groupByChain.title")} checked={checked} onChange={onChange} />
        </Layout>
    );
};

const Layout = styled(Flex)`
    margin-bottom: 2rem;
`;
