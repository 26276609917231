import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetEmergencyOrdersForShipToQuery } from "src/api";
import { createSelectColumnFilter, EmergencyOrderProcessedCell, HighlightCell, TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, LongTextCell, TableNames } from "src/table";
import type { ColumnsType, EmergencyOrderProductPackageListDto } from "src/types";
import { EmergencyOrderProductPackageStatusEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";

const createColumns = (t: TFunction): ColumnsType<EmergencyOrderProductPackageListDto> => [
    {
        collapse: true,
        noWrap: true,
        accessor: "requestedOn" as const,
        Header: t("shipToDetail.emergencyOrders.table.requestedOn") as string,
        disableFilters: true,
        Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) => dateTimeFormatter(value, DATE_TIME_FORMAT),
    },
    {
        width: 60,
        minWidth: 100,
        Header: t("shipToDetail.emergencyOrders.table.status") as string,
        accessor: "status" as const,
        Filter: createSelectColumnFilter(
            enumToLocalizedOptions("EmergencyOrderProductPackageStatusEnum", EmergencyOrderProductPackageStatusEnum),
        ),
        Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) =>
            getTranslatedEnumValue("EmergencyOrderProductPackageStatusEnum", value),
    },
    {
        width: 400,
        minWidth: 200,
        Header: t("shipToDetail.emergencyOrders.table.name") as string,
        accessor: "productPackageName" as const,
        Filter: TextColumnFilter,
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.tierNumber") as string,
        accessor: "tierNumber" as const,
        align: "center",
        disableFilters: true,
        Cell: ({ row }: Cell<EmergencyOrderProductPackageListDto>) => (
            <HighlightCell highlight={!row.original.isDefaultTier}>
                {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
            </HighlightCell>
        ),
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.dailyLimit") as string,
        accessor: "dailyLimit" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.monthlyLimit") as string,
        accessor: "monthlyLimit" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.monthlyVolumeAdjustment") as string,
        accessor: "monthlyVolumeAdjustment" as const,
        disableFilters: true,
        align: "center",
        Cell: ({ row }: Cell<EmergencyOrderProductPackageListDto>) => (
            <div>
                {row.values.status === EmergencyOrderProductPackageStatusEnum.Waiting
                    ? row.values.monthlyVolumeAdjustment
                    : row.original.monthlyVolumeAdjustmentBeforeProcess}
            </div>
        ),
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.requestedAmount") as string,
        accessor: "requestedAmount" as const,
        disableFilters: true,
        align: "center",
    },
    {
        collapse: true,
        Header: t("shipToDetail.emergencyOrders.table.processed.header") as string,
        accessor: "approvedThisMonth" as const,
        disableFilters: true,
        align: "center",
        Cell: ({ row }: Cell<EmergencyOrderProductPackageListDto>) => <EmergencyOrderProcessedCell row={row.original} />,
    },
    {
        minWidth: 100,
        Header: t("shipToDetail.emergencyOrders.table.orderReason") as string,
        accessor: "orderReason" as const,
        disableFilters: true,
        Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) => <LongTextCell text={value} charLimit={10} />,
    },
];

export const tableModule = createTableModule<EmergencyOrderProductPackageListDto>(
    TableNames.shipToEmergencyOrders,
    useGetEmergencyOrdersForShipToQuery,
    createColumns,
);
