import type { FC } from "react";

import { Button } from "src/components";
import { useTranslation } from "src/translations";
import { ButtonLayout } from "../components";

export const CopyTiersButton: FC<{ onCopyTiers: () => void }> = ({ onCopyTiers }) => {
    const { t } = useTranslation();

    return (
        <ButtonLayout>
            <Button onClick={onCopyTiers}>{t("copyTiers.copyTiers")}</Button>
        </ButtonLayout>
    );
};
