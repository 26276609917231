import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { createGlobalErrorMessage, useApproveSupplyLimitSuggestionMutation, useRefuseSupplyLimitSuggestionMutation } from "src/api";
import { groupedTableModule } from "src/app/dashboard/containers/supplyLimitSuggestionsWidget/groupedTable";
import { NotificationTypes, useNotifications } from "src/notifications";
import { useTranslation } from "src/translations";
import type { RequestErrorType } from "src/types";
import { useAppDispatch } from "src/utils";
import { DashboardCard } from "../DashboardCard";
import { tableModule } from "./table";
import { createActionColumn, createActionColumnForGroupedTable, createActionsForSelection, createExpandRow } from "./utils";

type SupplyLimitSuggestionsWidgetProps = Readonly<{
    shouldGroup: boolean;
}>;
export const SupplyLimitSuggestionsWidget: FC<SupplyLimitSuggestionsWidgetProps> = ({ shouldGroup }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const notifications = useNotifications();
    const dispatch = useAppDispatch();

    const [approveSuggestions] = useApproveSupplyLimitSuggestionMutation();
    const [refuseSuggestions] = useRefuseSupplyLimitSuggestionMutation();

    const onApprove = (supplyLimitSuggestionIds: ReadonlyArray<number>) => {
        approveSuggestions(supplyLimitSuggestionIds)
            .unwrap()
            .then(() => {
                dispatch(tableModule.resetTableSelectionAction);
                dispatch(groupedTableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("dashboard.supplyLimitSuggestions.approveSuccess", { count: supplyLimitSuggestionIds.length }),
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(
                    error,
                    t("dashboard.supplyLimitSuggestions.approveError", { count: supplyLimitSuggestionIds.length }),
                );
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    type: NotificationTypes.error,
                });
            });
    };

    const onRefuse = (supplyLimitSuggestionIds: ReadonlyArray<number>) => {
        refuseSuggestions(supplyLimitSuggestionIds)
            .unwrap()
            .then(() => {
                dispatch(tableModule.resetTableSelectionAction);
                dispatch(groupedTableModule.resetTableSelectionAction);
                notifications.showNotification({
                    title: t("common.form.successTitle"),
                    message: t("dashboard.supplyLimitSuggestions.refuseSuccess", { count: supplyLimitSuggestionIds.length }),
                    type: NotificationTypes.success,
                });
            })
            .catch((e) => {
                const error = e as RequestErrorType;
                const errorMessage = createGlobalErrorMessage(
                    error,
                    t("dashboard.supplyLimitSuggestions.refuseError", { count: supplyLimitSuggestionIds.length }),
                );
                notifications.showNotification({
                    title: t("common.form.errorTitle"),
                    message: errorMessage,
                    type: NotificationTypes.error,
                });
            });
    };

    return (
        <DashboardCard>
            {shouldGroup ? (
                <groupedTableModule.Container
                    expandedRow={createExpandRow}
                    actions={createActionColumnForGroupedTable(t, navigate, onApprove, onRefuse)}
                    actionsForSelection={createActionsForSelection(t, onApprove, onRefuse)}
                    isSelectable
                    canSelectAll={false}
                />
            ) : (
                <tableModule.Container
                    actions={createActionColumn(t, navigate, onApprove, onRefuse)}
                    actionsForSelection={createActionsForSelection(t, onApprove, onRefuse)}
                    isSelectable
                    canSelectAll={false}
                />
            )}
        </DashboardCard>
    );
};
