import i18n from "i18next";
import type { FC } from "react";

import { useUpdateCustomerCredibilityMutation } from "src/api";
import { Button, ModalButtonLayout, Select, SelectProps } from "src/components";
import { composeValidators, Form, InputField, required } from "src/form";
import { useTranslation } from "src/translations";
import { CustomerCredibilityEnum } from "src/types";

type ChangeCredibilityFormProps = Readonly<{
    onClose: () => void;
    customerId: number;
    customerCredibility?: CustomerCredibilityEnum;
}>;
export const ChangeCredibilityForm: FC<ChangeCredibilityFormProps> = ({ onClose, customerId, customerCredibility }) => {
    const { t } = useTranslation();
    const [updateCustomerCredibility] = useUpdateCustomerCredibilityMutation();

    return (
        <Form
            initialValues={{ credibility: customerCredibility ?? "" }}
            onSubmit={(values: { credibility: CustomerCredibilityEnum }) => updateCustomerCredibility({ ...values, customerId })}
            onSuccess={onClose}
        >
            {({ dirty }) => {
                return (
                    <>
                        <InputField<SelectProps["value"], SelectProps>
                            name="credibility"
                            input={Select}
                            validate={composeValidators(required)}
                            inputProps={{
                                placeholder: t("customers.changeCredibilityModal.placeholder"),
                                options: sortedCustomerCredibilityEnumToLocalizedOptions(),
                                clearable: false,
                                defaultValue: CustomerCredibilityEnum.Good,
                            }}
                        />
                        <ModalButtonLayout>
                            <Button disabled={!dirty} type="submit">
                                {t("common.update")}
                            </Button>
                            <Button type="button" onClick={onClose} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                );
            }}
        </Form>
    );
};

const statusWeights: Record<CustomerCredibilityEnum, number> = {
    [CustomerCredibilityEnum.Good]: 3,
    [CustomerCredibilityEnum.Suspicious]: 2,
    [CustomerCredibilityEnum.Bad]: 1,
};

export const sortedCustomerCredibilityEnumToLocalizedOptions = (): { label: string; value: string; reverseOrder?: boolean }[] =>
    Object.values(CustomerCredibilityEnum)
        .sort((a: CustomerCredibilityEnum, b: CustomerCredibilityEnum) => {
            const weightA = statusWeights[a] || 0;
            const weightB = statusWeights[b] || 0;

            return weightB - weightA;
        })
        .map((enumValue) => ({
            label: i18n.t(`enum.customerCredibility.${enumValue}`),
            value: enumValue,
        }));
