import { useGetGroupedSupplyLimitSuggestionsQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { ExpandTableSupplySuggestionType } from "src/types";
import { createColumns } from "./createColumns";

export const groupedTableModule = createTableModule<ExpandTableSupplySuggestionType>(
    TableNames.groupedSupplyLimitSuggestions,
    useGetGroupedSupplyLimitSuggestionsQuery,
    createColumns,
);
