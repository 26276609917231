import { type FC, useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetCustomersWithSameAddressOrAcnQuery, useUpdateShipToWithSupplyLimitsMutation } from "src/api";
import arrowIcon from "src/assets/icons/previous-to-new-ship-to-arrow.svg";
import { Flex, GridElement, Icon, SvgIcon, Text } from "src/components";
import { PageLayout } from "src/layout";
import { NotificationTypes, useNotifications } from "src/notifications";
import { createSearchParamsAsString, CustomersTab, routes, TAB_PARAM } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { StartPointEnum } from "../../approveCustomer";
import { ACN_PARAM, START_POINT_PARAM } from "../../constants";
import { CopyTiersLayout, CopyTiersLayoutAreas } from "../components";
import { type SupplyLimitsSettingsType, isStartPointEnum } from "../types";
import { CopyTiersButton } from "./CopyTiersButton";
import { NewShipTo } from "./NewShipTo";
import { PreviousShipTo } from "./PreviousShipTo";
import { createExpandRow, supplyLimitsTable } from "./SupplyLimitsTable";

export const CopyTiersToExistingShipTo: FC = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const notifications = useNotifications();
    const navigate = useNavigate();

    const { targetShipToId } = routes.copyTiers.useParams();
    const {
        data: previousShipTos,
        isLoading,
        isError,
    } = useGetCustomersWithSameAddressOrAcnQuery({ targetShipToId: Number(targetShipToId), acn: searchParams.get(ACN_PARAM) || undefined });

    const [currentShipToIndex, setCurrentShipToIndexIndex] = useState(0);

    const supplyLimitsSettings: SupplyLimitsSettingsType | undefined = useMemo(() => {
        if (previousShipTos) {
            const selectedShipTo = previousShipTos[currentShipToIndex];

            return {
                id: selectedShipTo.id,
                acn: selectedShipTo.acn,
                hasCustomizedSupplyLimits: selectedShipTo.hasCustomizedSupplyLimits,
            };
        }

        return undefined;
    }, [currentShipToIndex, previousShipTos]);

    const [updateShipToWithSupplyLimits] = useUpdateShipToWithSupplyLimitsMutation();
    const onCopyTiers = useCallback(async () => {
        if (!previousShipTos) {
            return;
        }

        const startPoint = searchParams.get(START_POINT_PARAM);
        try {
            await updateShipToWithSupplyLimits({
                sourceShipToId: previousShipTos[currentShipToIndex].id,
                targetShipToId: Number(targetShipToId),
            }).unwrap();
            notifications.showNotification({
                title: t("common.form.successTitle"),
                message: t("copyTiers.copyTiersSuccess"),
                type: NotificationTypes.success,
            });
            if (isStartPointEnum(startPoint)) {
                if (startPoint === StartPointEnum.Detail && targetShipToId) {
                    navigate(`${routes.customerDetail.fillPathParams({ customerId: targetShipToId })}`);
                } else {
                    navigate(
                        `${routes.customers.path}?${createSearchParamsAsString({
                            [TAB_PARAM]: CustomersTab.new,
                        })}`,
                    );
                }
            }
        } catch (error) {
            notifications.showNotification({
                title: t("common.form.errorTitle"),
                message: `${t("copyTiers.error")}`,
                type: NotificationTypes.error,
            });
        }
    }, [updateShipToWithSupplyLimits, notifications, t, targetShipToId, previousShipTos, currentShipToIndex, navigate, searchParams]);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    name: t("customers.name"),
                    route: routes.customers.path,
                    icon: Icon.People,
                },
                {
                    name: "Copy Tiers",
                    route: routes.copyTiers.path,
                },
            ]}
        >
            <Flex flexDirection="column">
                <CopyTiersLayout>
                    <PreviousShipTo
                        previousShipTos={previousShipTos}
                        isLoading={isLoading}
                        isError={isError}
                        currentShipToIndex={currentShipToIndex}
                        setCurrentShipToIndexIndex={setCurrentShipToIndexIndex}
                    />
                    <GridElement gridArea={CopyTiersLayoutAreas.arrow} justifySelf="center" alignSelf="center">
                        <SvgIcon icon={arrowIcon} />
                    </GridElement>
                    <NewShipTo supplyLimitsSettings={supplyLimitsSettings} />
                    <CopyTiersButton onCopyTiers={onCopyTiers} />
                </CopyTiersLayout>
                <Flex flexDirection="column" gap="1rem">
                    <Text color={Color.supportNavy500} size="1.8rem" weight={700}>
                        {t("copyTiers.supplyLimits.title")}
                    </Text>
                    {previousShipTos && (
                        <supplyLimitsTable.Container
                            expandedRow={createExpandRow}
                            additionalQueryParams={{ customerId: previousShipTos[currentShipToIndex].id }}
                        />
                    )}
                </Flex>
            </Flex>
        </PageLayout>
    );
};
