import type { TFunction } from "i18next";
import type { FC } from "react";
import type { NavigateFunction } from "react-router-dom";
import type { Row } from "react-table";

import { ActionIcon, Button, Confirm, Icon, TableAction, TableActionForSelection, Text } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { routes } from "src/routes";
import { type ExpandRowType, getChildIdFromRowId, getParentAndChildrenFromTablePage, ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type {
    CustomerChainWithSupplyLimitSuggestionsDtoWithId,
    ExpandTableSupplySuggestionType,
    SupplyLimitSuggestionDto,
} from "src/types";
import { isEmpty } from "src/utils";

export const createActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
        refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
    ) =>
    ({ original }: Row<SupplyLimitSuggestionDto>): JSX.Element =>
        (
            <TableActions
                supplyLimitSuggestion={original}
                t={t}
                navigate={navigate}
                approveSuggestions={approveSuggestions}
                refuseSuggestions={refuseSuggestions}
            />
        );

export const createActionColumnForGroupedTable =
    (
        t: TFunction,
        navigate: NavigateFunction,
        approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
        refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
    ) =>
    ({ depth, id }: Row<ExpandTableSupplySuggestionType>, page: Array<Row<ExpandTableSupplySuggestionType>>): JSX.Element | null => {
        const chainWithSupplySuggestions = getParentAndChildrenFromTablePage<
            ExpandTableSupplySuggestionType,
            CustomerChainWithSupplyLimitSuggestionsDtoWithId,
            SupplyLimitSuggestionDto
        >(page, id);
        const supplySuggestion = chainWithSupplySuggestions?.child;
        return depth === 1 && !isEmpty(supplySuggestion) ? (
            <TableActions
                supplyLimitSuggestion={supplySuggestion}
                t={t}
                navigate={navigate}
                approveSuggestions={approveSuggestions}
                refuseSuggestions={refuseSuggestions}
            />
        ) : null;
    };

type TableActionProps = Readonly<{
    supplyLimitSuggestion: SupplyLimitSuggestionDto;
    t: TFunction;
    navigate: NavigateFunction;
    approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void;
    refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void;
}>;
const TableActions: FC<TableActionProps> = ({ supplyLimitSuggestion, approveSuggestions, refuseSuggestions, navigate, t }) => (
    <TableAction>
        {supplyLimitSuggestion.suggestedTierId ? (
            <ConfirmContextConsumer>
                {({ showConfirm }) => {
                    const confirm = async () => {
                        const confirmResult = await showConfirm({
                            title: t("dashboard.supplyLimitSuggestions.changeTier.confirm.title"),
                            content: t("dashboard.supplyLimitSuggestions.changeTier.confirm.content", {
                                fromMonthlyLimit: supplyLimitSuggestion.currentTierMonthlyLimit,
                                toMonthlyLimit: supplyLimitSuggestion.suggestedTierMonthlyLimit,
                            }),
                        });
                        if (confirmResult) {
                            approveSuggestions([supplyLimitSuggestion.id]);
                        }
                    };
                    return (
                        <ActionIcon
                            onClick={confirm}
                            color={Color.success100}
                            tooltip={t("dashboard.supplyLimitSuggestions.changeTier.tooltip")}
                        >
                            <Icon.Checkmark size={ICON_TABLE_SIZE} color={Color.success500} />
                        </ActionIcon>
                    );
                }}
            </ConfirmContextConsumer>
        ) : (
            <ActionIcon
                onClick={() =>
                    navigate(
                        routes.supplyLimitsDetail.fillPathParams({ productPackageId: supplyLimitSuggestion.productPackageId.toString() }),
                    )
                }
                color={Color.accent100}
                tooltip={t("dashboard.supplyLimitSuggestions.createSupplyLimit")}
            >
                <Icon.AddSquare size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
            </ActionIcon>
        )}
        <ConfirmContextConsumer>
            {({ showConfirm }) => {
                const confirm = async () => {
                    const confirmResult = await showConfirm({
                        title: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.title"),
                        content: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.content"),
                    });
                    if (confirmResult) {
                        refuseSuggestions([supplyLimitSuggestion.id]);
                    }
                };
                return (
                    <ActionIcon
                        onClick={confirm}
                        color={Color.error100}
                        tooltip={t(`dashboard.supplyLimitSuggestions.removeSuggestion.tooltip`)}
                    >
                        <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
                    </ActionIcon>
                );
            }}
        </ConfirmContextConsumer>
    </TableAction>
);

export const createActionsForSelection =
    (
        t: TFunction,
        bulkApprove: (suggestionIds: ReadonlyArray<number>) => void,
        bulkRefuse: (suggestionIds: ReadonlyArray<number>) => void,
    ) =>
    (_: boolean, selectedRowIds: Record<number, boolean>) => {
        const selectedIds = Object.entries(selectedRowIds).map(([key]) => {
            const childRowId = getChildIdFromRowId(key);
            return childRowId ? Number(childRowId) : Number(key);
        });
        return (
            <TableActionForSelection>
                <Confirm
                    title={t("dashboard.supplyLimitSuggestions.approveConfirm.title")}
                    content={t("dashboard.supplyLimitSuggestions.approveConfirm.content")}
                    confirmButtonTransKey="dashboard.supplyLimitSuggestions.approveSelected"
                    onConfirm={() => {
                        bulkApprove(selectedIds);
                    }}
                >
                    {(open) => (
                        <Button disabled={selectedIds.length === 0} type="button" onClick={open}>
                            {t("dashboard.supplyLimitSuggestions.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <Confirm
                    title={t("dashboard.supplyLimitSuggestions.refuseConfirm.title")}
                    content={t("dashboard.supplyLimitSuggestions.refuseConfirm.content")}
                    confirmButtonTransKey="dashboard.supplyLimitSuggestions.refuseSelected"
                    onConfirm={() => {
                        bulkRefuse(selectedIds);
                    }}
                >
                    {(open) => (
                        <Button disabled={selectedIds.length === 0} variant="outline" type="button" onClick={open}>
                            {t("dashboard.supplyLimitSuggestions.refuseSelected")}
                        </Button>
                    )}
                </Confirm>
            </TableActionForSelection>
        );
    };

export const createExpandRow = (row: Row<ExpandTableSupplySuggestionType>): ExpandRowType => ({
    accessor: "shipToName",
    colspan: 10,
    component: (
        <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
            {row.original.chain}
        </Text>
    ),
});
