import { useParams } from "react-router-dom";

import type { AnyRecord, EmptyObject } from "src/types";
import { getEmptyObject, isArray, isNumber, isObject, isString } from "src/utils";

const SUPPLY_LIMITS = "supply-limits";
const CUSTOMERS = "customers";

const PRODUCT_PACKAGE_ID = "productPackageId" as const;
const CUSTOMER_ID = "customerId" as const;
const TARGET_SHIP_TO_ID = "targetShipToId" as const;

type Path = string;

type PathParams = Readonly<Record<string, string>>;

type ProductPackageDetailPath = Readonly<{
    [PRODUCT_PACKAGE_ID]: string;
}>;

type CustomerDetailPath = Readonly<{
    [CUSTOMER_ID]: string;
}>;

type CopyTiersPath = Readonly<{
    [TARGET_SHIP_TO_ID]: string;
}>;

type Route<P extends PathParams> = Readonly<{
    path: Path;
    fillPathParams: (params?: P) => Path;
    useParams: () => P;
}>;

const fillSupplyLimitsDetailPathParams = ({ productPackageId }: ProductPackageDetailPath) => `/${SUPPLY_LIMITS}/${productPackageId}`;
const fillSupplyLimitsBulkEditPathParams = ({ productPackageId }: ProductPackageDetailPath) =>
    `/${SUPPLY_LIMITS}/${productPackageId}/bulk-edit`;
const fillCustomerDetailPathParams = ({ customerId }: CustomerDetailPath) => `/${CUSTOMERS}/${customerId}`;
const fillCopyTiersPathParams = ({ targetShipToId }: CopyTiersPath) => `/${CUSTOMERS}/copy-tiers/${targetShipToId}`;

/**
 * Generate path description. Just add ":" before parameters names and use them in fillPathParamsFunc.
 * @param filPathParamsFunc - path for fill parameters could be used, just add ":" before route parameters names
 * @param params - parameters of the route.
 */
const generatePath = <P extends PathParams>(filPathParamsFunc: (params: P) => Path, params: P): Path =>
    filPathParamsFunc(Object.entries(params).reduce((result, [key, param]) => Object.assign(result, { [key]: `:${param}` }), {} as P));

/**
 * Creates simple route without path params.
 * @param path Route path.
 */
const createSimpleRoute = (path: Path): Route<EmptyObject> => ({
    path,
    fillPathParams: () => path,
    useParams: getEmptyObject,
});

export const createSearchParamsAsString = (params: AnyRecord): string => {
    const UrlParams = new URLSearchParams();
    const paramsArray = Object.entries(params);
    paramsArray.forEach(([key, searchParam]) => {
        const searchParamValue = isObject(searchParam) && !isArray(searchParam) ? createSearchParamsAsString(searchParam) : searchParam;
        if (isArray(searchParamValue)) {
            searchParamValue.forEach((value) => {
                if (isString(value)) {
                    UrlParams.append(key, value);
                } else if (isNumber(value)) {
                    UrlParams.append(key, value.toString());
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(`Search param "${key}" has unsupported value: ${value}`);
                }
            });
        } else {
            UrlParams.append(key, searchParamValue);
        }
    });
    return UrlParams.toString();
};

export const routes = {
    // Public route
    emergencyOrders: createSimpleRoute(`/emergency-orders`),
    // Protected routes
    dashboard: createSimpleRoute(`/`),
    products: createSimpleRoute(`/products`),
    prices: createSimpleRoute(`/products/prices`),
    customers: createSimpleRoute(`/${CUSTOMERS}`),
    importExport: createSimpleRoute(`/importExport`),
    customerDetail: {
        path: generatePath(fillCustomerDetailPathParams, { [CUSTOMER_ID]: CUSTOMER_ID }),
        fillPathParams: fillCustomerDetailPathParams,
        useParams: () => useParams<CustomerDetailPath>(),
    } as Route<CustomerDetailPath>,
    copyTiers: {
        path: generatePath(fillCopyTiersPathParams, { [TARGET_SHIP_TO_ID]: TARGET_SHIP_TO_ID }),
        fillPathParams: fillCopyTiersPathParams,
        useParams: () => useParams<CopyTiersPath>(),
    } as Route<CopyTiersPath>,
    supplyLimits: createSimpleRoute(`/${SUPPLY_LIMITS}`),
    supplyLimitsDetail: {
        path: generatePath(fillSupplyLimitsDetailPathParams, { [PRODUCT_PACKAGE_ID]: PRODUCT_PACKAGE_ID }),
        fillPathParams: fillSupplyLimitsDetailPathParams,
        useParams: () => useParams<ProductPackageDetailPath>(),
    } as Route<ProductPackageDetailPath>,
    supplyLimitsBulkEdit: {
        path: generatePath(fillSupplyLimitsBulkEditPathParams, { [PRODUCT_PACKAGE_ID]: PRODUCT_PACKAGE_ID }),
        fillPathParams: fillSupplyLimitsBulkEditPathParams,
        useParams: () => useParams<ProductPackageDetailPath>(),
    } as Route<ProductPackageDetailPath>,
} as const;
