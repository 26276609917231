import type { FC } from "react";
import { useState } from "react";

import inactivePayer from "src/assets/icons/inactive-customer.svg";
import { Button, Flex, SvgIcon, Text } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { CustomerDto } from "src/types";
import { ActionRequestEnum } from "src/types";
import { ApproveModal, StartPointEnum } from "../approveCustomer";
import { Card } from "../components";

type NotApprovedProps = {
    customerDetail?: CustomerDto;
};

export const NotApproved: FC<NotApprovedProps> = ({ customerDetail }) => {
    const { t } = useTranslation();
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>(undefined);

    const isPayerUnApproved = customerDetail?.payer?.actionRequests?.includes(ActionRequestEnum.Approve);

    return (
        <Card>
            <Flex flexDirection="column" alignItems="center" gap="2rem">
                <SvgIcon icon={inactivePayer} width={50} height={50} />
                <Text size="1.6rem" color={Color.supportNavy300}>
                    {t("payerDetail.notApproved.notApprovedCustomer")}
                </Text>
            </Flex>
            <Flex gap="0.5rem" justifyContent="center" alignItems="center" flexDirection="column">
                <Button type="button" disabled={isPayerUnApproved} onClick={() => setSelectedCustomerId(customerDetail?.id)}>
                    {t("payerDetail.notApproved.approve")}
                </Button>
                {isPayerUnApproved && (
                    <Text size="1.1rem" color={Color.supportNavy300}>
                        {t("payerDetail.notApproved.payerInactive")}
                    </Text>
                )}
                <ApproveModal
                    startPoint={StartPointEnum.Detail}
                    customerId={selectedCustomerId}
                    onClose={() => setSelectedCustomerId(undefined)}
                />
            </Flex>
        </Card>
    );
};
