import { useGetWaitingEmergencyOrdersQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { EmergencyOrderProductPackageListDto } from "src/types";
import { createColumns } from "./createColumns";

export const tableModule = createTableModule<EmergencyOrderProductPackageListDto>(
    TableNames.emergencyOrders,
    useGetWaitingEmergencyOrdersQuery,
    createColumns,
);
