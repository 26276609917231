import type { FC } from "react";
import { ChangeEvent, useState } from "react";

import { Button, Grid, Text, TextInput } from "src/components";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { ShipToForCopySupplyLimitsDto } from "src/types";
import { isEmpty } from "src/utils";
import { Found } from "./Found";
import { NotFound } from "./NotFound";
import { Searching } from "./Searching";
import { Widget } from "./Widget";

type CopyCustomerWidgetComponentProps = {
    isSearching: boolean;
    sameCustomers?: ReadonlyArray<ShipToForCopySupplyLimitsDto>;
    onSearchByAcn: (acn: string) => void;
    notFoundText: string;
    foundText: string;
    foundLink: string;
};

export const CopyCustomerWidgetComponent: FC<CopyCustomerWidgetComponentProps> = ({
    isSearching,
    onSearchByAcn,
    sameCustomers,
    foundLink,
    notFoundText,
    foundText,
}) => {
    const { t } = useTranslation();
    const [localAcn, setLocalAcn] = useState("");

    return (
        <Widget>
            {isSearching ? (
                <Searching text={t("customers.approveForm.widget.searching")} />
            ) : sameCustomers && sameCustomers.length > 0 ? (
                <Found text={foundText} link={foundLink} />
            ) : (
                <NotFound text={notFoundText} />
            )}
            <Text size="1.4rem" color={Color.supportNavy300} align="center">
                {t("customers.approveForm.widget.or")}
            </Text>
            <Grid gridAutoFlow="row" gap="0.2rem">
                <Text color={Color.supportNavy500} size="1.4rem">
                    {t("customers.approveForm.widget.acnLabel")}
                </Text>
                <Grid gridTemplateColumns="1fr auto" gap="4rem" justifyContent="space-between" alignItems="center">
                    <TextInput
                        withoutErrorPlaceholder
                        value={localAcn}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLocalAcn(e.target.value)}
                        placeholder={t("customers.approveForm.widget.acnPlaceholder")}
                        onKeyDown={(e: KeyboardEvent) => {
                            // We have to catch the enter key here, because otherwise the form will be submitted
                            if (e.key === "Enter") {
                                e.preventDefault();
                                onSearchByAcn(localAcn);
                            }
                        }}
                    />
                    <Button onClick={() => onSearchByAcn(localAcn)} variant="subtle" disabled={isEmpty(localAcn)}>
                        {t("customers.approveForm.widget.acnButton")}
                    </Button>
                </Grid>
            </Grid>
            <Text size="1.1rem" color={Color.supportNavy400} align="center">
                {t("customers.approveForm.widget.acnDescription")}
            </Text>
        </Widget>
    );
};
