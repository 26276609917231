import { useGetGroupedWaitingEmergencyOrdersQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { ExpandEmergencyOrdersType } from "src/types";
import { createColumns } from "./createColumns";

export const groupedTableModule = createTableModule<ExpandEmergencyOrdersType>(
    TableNames.groupedEmergencyOrders,
    useGetGroupedWaitingEmergencyOrdersQuery,
    createColumns,
);
