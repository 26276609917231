import { Switch as MSwitch, SwitchProps as MSwitchProps } from "@mantine/core";
import styled from "styled-components";

type SwitchProps = MSwitchProps;

export const Switch = styled(MSwitch)<SwitchProps>`
    display: flex;

    & .mantine-Switch-track {
        border-color: ${({ theme }) => theme.color.neutral400};
        background-color: ${({ theme }) => theme.color.neutral400};
        cursor: pointer;

        & .mantine-Switch-thumb {
            border-color: ${({ theme }) => theme.color.neutral400};
        }
    }

    & input:checked + .mantine-Switch-track {
        background-color: ${({ theme }) => theme.color.primary600};
        border-color: ${({ theme }) => theme.color.primary600};

        & .mantine-Switch-thumb {
            border-color: ${({ theme }) => theme.color.primary600};
            background-color: ${({ theme }) => theme.color.white};
        }
    }

    & input:disabled + .mantine-Switch-track {
        border-color: ${({ theme }) => theme.color.neutral400};
        background-color: ${({ theme }) => theme.color.neutral400};

        & .mantine-Switch-thumb {
            border-color: ${({ theme }) => theme.color.neutral400};
            background-color: ${({ theme }) => theme.color.white};
        }
    }

    & .mantine-Switch-label {
        font-weight: 700;
        cursor: pointer;
        padding: 0.8rem 1.6rem;
        text-decoration: none;
        font-size: 1.4rem;

        &[data-disabled="true"] {
            color: ${({ theme }) => theme.color.neutral400};
        }
    }
`;
