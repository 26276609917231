import type { TFunction } from "react-i18next";
import type { Cell } from "react-table";

import { useGetPayersQuery } from "src/api";
import { sortedCustomerCredibilityEnumToLocalizedOptions } from "src/app/customers/containers/ChangeCredibilityForm";
import { createSelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import {
    type EmergencyOrderProductPackageListDto,
    type ExpandColumnsType,
    type ExpandTableCustomerType,
    CustomerListDto,
    CustomerStatusEnum,
    CustomerTypeEnum,
} from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";
import { customerCategoryFormatter } from "../../utils";

const createColumns = (t: TFunction): ExpandColumnsType<ExpandTableCustomerType> => {
    return [
        {
            collapse: true,
            accessor: "category" as const,
            Header: t("customers.table.category") as string,
            noWrap: true,
            disableFilters: true,
            align: "center",
            Cell: ({ value }: Cell<ExpandTableCustomerType>) => customerCategoryFormatter(t, value),
        },
        {
            width: 350,
            minWidth: 120,
            Header: t("customers.table.name") as string,
            accessor: "name" as const,
            Filter: TextColumnFilter,
            Cell: ({ row, value }: Cell<ExpandTableCustomerType>) => (
                <TableLink
                    to={routes.customerDetail.fillPathParams({
                        customerId: row.original.id.toString(),
                    })}
                >
                    {value}
                </TableLink>
            ),
        },
        {
            minWidth: 100,
            Header: t("customers.table.type") as string,
            accessor: "type" as const,
            Filter: createSelectColumnFilter(enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum)),
            Cell: ({ value }: Cell<CustomerListDto>) => getTranslatedEnumValue("CustomerTypeEnum", value),
        },
        {
            minWidth: 100,
            Header: t("customers.table.acn") as string,
            accessor: "acn" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("customers.table.address") as string,
            accessor: "street" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("customers.table.city") as string,
            accessor: "city" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 100,
            Header: t("customers.table.zip") as string,
            accessor: "zip" as const,
            Filter: TextColumnFilter,
            Cell: ({ value }: Cell<ExpandTableCustomerType>) => zipFormatter(value),
        },
        {
            minWidth: 100,
            Header: t("customers.table.tin") as string,
            accessor: "tin" as const,
            Filter: TextColumnFilter,
        },
        {
            minWidth: 120,
            width: 120,
            Header: t("customers.table.credibility") as string,
            accessor: "credibility" as const,
            Filter: createSelectColumnFilter(sortedCustomerCredibilityEnumToLocalizedOptions()),
            Cell: ({ value }: Cell<EmergencyOrderProductPackageListDto>) => getTranslatedEnumValue("customerCredibility", value),
        },
        {
            minWidth: 100,
            collapse: true,
            Header: t("customers.table.status") as string,
            accessor: "status" as const,
            Filter: createSelectColumnFilter(enumToLocalizedOptions("customerStatus", CustomerStatusEnum, [CustomerStatusEnum.New])),
            Cell: ({ value }: Cell<ExpandTableCustomerType>) => getTranslatedEnumValue("customerStatus", value),
        },
    ];
};

export const tableModule = createTableModule<ExpandTableCustomerType>(TableNames.customersAll, useGetPayersQuery, createColumns);
