import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { stringToBool } from "src/utils";
import { IS_EXISTING_SHIP_TO } from "../constants";
import { CopyTiersToExistingShipTo } from "./containers/CopyTiersToExistingShipTo";
import { CopyTiersToNewShipTo } from "./containers/CopyTiersToNewShipTo";

export const CopyTiers: FC = () => {
    const [searchParams] = useSearchParams();
    const isExistingShipTo = stringToBool(searchParams.get(IS_EXISTING_SHIP_TO));

    return isExistingShipTo ? <CopyTiersToExistingShipTo /> : <CopyTiersToNewShipTo />;
};
