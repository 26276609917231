import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Icon } from "src/components";
import { Color, IconSizes } from "src/theme";
import { Trans, useTranslation } from "src/translations";
import { getTranslatedEnumValue } from "src/utils";
import { INVOICING_FREQUENCY_PARAM, PAYMENT_DUE_PARAM } from "../../constants";
import { ButtonLayout } from "../components";

type ApproveButtonProps = {
    onApprove: () => void;
};

export const ApproveButton: FC<ApproveButtonProps> = ({ onApprove }) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const paymentDue = searchParams.get(PAYMENT_DUE_PARAM);
    const invoicingFrequency = searchParams.get(INVOICING_FREQUENCY_PARAM);

    return (
        <ButtonLayout>
            <Button onClick={onApprove}>{t("copyTiers.approveCustomer")}</Button>
            <Icon.QuestionCircle
                size={IconSizes.s}
                color={Color.supportNavy500}
                tooltip={
                    <Trans
                        i18nKey="copyTiers.approveCustomerTooltip"
                        values={{
                            paymentDue: paymentDue ? getTranslatedEnumValue("paymentDue", paymentDue) : "",
                            invoicingFrequency: invoicingFrequency ? getTranslatedEnumValue("invoicingFrequency", invoicingFrequency) : "",
                        }}
                        components={{ breakLine: <br /> }}
                    />
                }
                tooltipDelay={100}
                tooltipBackgroundColor={Color.supportNavy500}
                tooltipArrow={false}
            />
        </ButtonLayout>
    );
};
